import React from "react";
// import { useEffect } from 'react';
// nodejs library that concatenates classes
// import classNames from "classnames";
// react components for routing our app without refresh
// import { Link } from "react-router-dom";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";
import Footer from '../Footer';
import HeaderMenu from '../HeaderMenu';
import Banner from '../Banner';
import OurServices from '../OurServices';
import WhyChooseUs from '../WhyChooseUs';
import OurCustomers from "views/OurCustomers";
import Acheivements from '../Acheivements';
import LatestProjects from '../LatestProjects';
import WhoWeAre from '../WhoWeAre';
import Reviews from '../Reviews';
import LatestNews from '../LatestNews';

//import styles from "assets/jss/material-kit-react/views/home.js";
import '../../styles/index.css';

//const useStyles = makeStyles(styles);

export default function Home(props) {
  //const class = useStyles();
  //const { ...rest } = props;

  return (
    <div>

      <main className="main">
        <div className="main-inner">
          <HeaderMenu />
          <Banner />
          <OurServices />
          <WhyChooseUs />
          {/* <Reviews /> */}
          {/* <LatestProjects /> */}
          {/* <Acheivements />  */}
          {/* <WhoWeAre /> */}
          <OurCustomers />
        </div>
        <Footer />
      </main>

    </div>
  );
}
