import React from "react";

export default function Banner(props) {
  return (
    <div className="section-bg main-banner">
      <div className="main-banner-slider flickity-dots-absolute" data-flickity='{ "bgLazyLoad": 1, "bgLazyLoad": true, "fade": true, "prevNextButtons": false, "autoPlay": 7000, "pauseAutoPlayOnHover": false }'>
        <div className="main-banner-slider-item" data-flickity-bg-lazyload="/img/main-banner-img4.jpg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="main-banner-content">
                  <h1 className="main-banner-title">IT Solutions for Your Business</h1>
                  <div className="main-banner-desc">
                    <p>
                    <span className="primaryColor bold">Envy</span><span className="secondaryColor bold">Soft&nbsp;</span>
                      is an IT consulting group that can develop custom IT solutions for your business. Our team has decades of experience within our team.  
                      Reach out to our team to see what we can do for your company.
                    </p>
                  </div>
                  <div className="main-banner-btns">
                    <a href="/services" className="btn btn-widht-ico btn-small ripple">
                      <span>Learn More</span>
                      <svg className="btn-widht-ico-right" viewBox="0 0 13 9"><use xlinkHref="/img/sprite.svg#arrow-right"></use></svg>
                    </a>
                    {/* <a href="#!" className="btn btn-widht-ico btn-border btn-small ripple">
                      <span>Get in Touch</span>
                      <svg className="btn-widht-ico-right" viewBox="0 0 13 9"><use xlinkHref="/img/sprite.svg#arrow-right"></use></svg>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-banner-slider-item" data-flickity-bg-lazyload="/img/main-banner-img5.jpg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="main-banner-content">
                  <h2 className="main-banner-title">IT Solutions for Your Business</h2>
                  <div className="main-banner-desc">
                  <p>
                  <span className="primaryColor bold">Envy</span><span className="secondaryColor bold">Soft&nbsp;</span>
                      is an IT consulting group that can develop custom IT solutions for your business. Our team has decades of experience within our team.  
                      Reach out to our team to see what we can do for your company.
                    </p>
                  </div>
                  <div className="main-banner-btns">
                    <a href="/services" className="btn btn-widht-ico btn-small ripple">
                      <span>Learn More</span>
                      <svg className="btn-widht-ico-right" viewBox="0 0 13 9"><use xlinkHref="/img/sprite.svg#arrow-right"></use></svg>
                    </a>
                    {/* <a href="#!" className="btn btn-widht-ico btn-border btn-small ripple">
                      <span>Get in Touch</span>
                      <svg className="btn-widht-ico-right" viewBox="0 0 13 9"><use xlinkHref="/img/sprite.svg#arrow-right"></use></svg>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div >
        <div className="main-banner-slider-item" data-flickity-bg-lazyload="/img/main-banner-img6.jpg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="main-banner-content">
                  <h2 className="main-banner-title">IT Solutions for Your Business</h2>
                  <div className="main-banner-desc">
                  <p>
                  <span className="primaryColor bold">Envy</span><span className="secondaryColor bold">Soft&nbsp;</span>
                      is an IT consulting group that can develop custom IT solutions for your business. Our team has decades of experience within our team.  
                      Reach out to our team to see what we can do for your company.
                    </p>
                  </div>
                  <div className="main-banner-btns">
                    <a href="/services" className="btn btn-widht-ico btn-small ripple">
                      <span>Learn More</span>
                      <svg className="btn-widht-ico-right" viewBox="0 0 13 9"><use xlinkHref="/img/sprite.svg#arrow-right"></use></svg>
                    </a>
                    {/* <a href="#!" className="btn btn-widht-ico btn-border btn-small ripple">
                      <span>Get in Touch</span>
                      <svg className="btn-widht-ico-right" viewBox="0 0 13 9"><use xlinkHref="/img/sprite.svg#arrow-right"></use></svg>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div >
      </div >
    </div >
  );
}
