import React from "react";
import Footer from '../Footer';
import HeaderMenu from '../HeaderMenu';
import '../../styles/index.css';
import WhyChooseUs from "views/WhyChooseUs";
import OurCustomers from "views/OurCustomers";


export default function Home(props) {
  return (
    <div>

      <main className="main">
        <div className="main-inner">
          <HeaderMenu />

          <div class="main-content">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="wrapp-page-title page-title-center">
                    {/* <div class="page-subtitle">MORE INFO</div> */}
                    <h1 class="page-title">About us</h1>
                  </div>
                  <div class="content">
                    <div class="img-style">
                      <img src="img/about-img.jpg" alt="" />
                    </div>
                    <h2>
                      Founded
                    </h2>
                    <p className="largeFont">
                    <span className="primaryColor bold">Envy</span><span className="secondaryColor bold">Soft</span> was created by two software developers
                    who spent years working for large corporations, but decided to band together to create custom software for businesses that do not have an IT department
                    to leverage.  We look forward to using our decades of large company experience to help the everyday businesses grow and prosper. 
                    </p>
                    <h3>Developing High Quality Apps</h3>
                    <p className="largeFont">Our <span className="primaryColor bold">Envy</span><span className="secondaryColor bold">Soft</span> 
                      &nbsp;team utilizes decades of experience to develop full scale, highly available applications for your business.  We know know any
                      downtime to your site can cost you money, which is why we make sure to create website and applications that help keep your business humming along. </p>
                    <h3>Providing Reliable Software</h3>
                    <p className="largeFont">We understand that many companies out there can offer to create you a new application for your business, but 
                    you will most likely end up with a team of low level software developers who will give you an application filled with bugs and issues.  
                    &nbsp;&nbsp;<span className="primaryColor bold">Envy</span><span className="secondaryColor bold">Soft</span> has a team of highly experienced engineers
                    who have decades working on applications using only the best practices.  You can be assures that&nbsp; 
                    <span className="primaryColor bold">Envy</span><span className="secondaryColor bold">Soft</span> will create a highly reliable application that
                    will last your business for years to come.  Please feel reach out to us for examples and testimonials from one of our other happy customers.
                    likely have your application built with</p>
                    <h3>Supporting Our Clients</h3>
                    <p className="largeFont">As part of one of our hosted subscriptions, you can enjoy our white glove service and support.  Whether you use one of our shared hosting
                      solutions or a fully developed cloud application suite, we strive to ensure your site is highly available and always up on the latest server
                      software. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <WhyChooseUs />

          <OurCustomers />

        </div>
        <Footer />
      </main>

    </div>
  );
}
