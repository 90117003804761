import React from "react";
import Footer from '../Footer';
import HeaderMenu from '../HeaderMenu';

import '../../styles/index.css';

export default function Services(props) {
  return (
    <div>
      <main className="main">
        <div className="main-inner">
          <HeaderMenu />
          <div className="main-content">
            <div className="container">
              <div className="row items">
                <div className="col-12">
                  <div className="wrapp-page-title page-title-center">
                    {/* <div className="page-subtitle">Areas what we serv</div> */}
                    <h1 className="page-title">Our Services</h1>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 item gallery-type1">
                  <img src="/img/services-1.jpg" className="gallery-item img-style"/>
                </div>
                <div className="col-lg-8 col-md-6 col-12">
                  <p className="largeFont"><span className="primaryColor bold">Envy</span><span className="secondaryColor bold">Soft&nbsp;</span>
                   can provide you with almost any number of IT development services.  Our team has worked on various projects from creating a customer facing
                   website for a business to creating backend serverless functions to support high end computing needs.  If you are not sure
                   of what you need for your business, please reach out to the team here at EnvySoft and one of our senior software engineers will gladly meet
                   with your team to come up with a custom solution to meet your needs.  Email us now at <a href="mailto:info@envysoft.io">info@envysoft.io</a> to
                   get started.</p>
                </div>
                <div className="col-lg-4 col-md-6 col-12 item">
                  <span className="services-item services-item-modern item-style">
                    <div className="services-item-ico">
                      <i className="material-icons material-icons-outlined md-48">web</i>
                    </div>
                    <div className="services-item-ico-bg">
                      <i className="material-icons material-icons-outlined">web</i>
                    </div>
                    <h4 className="services-item-title">Website Creation</h4>
                    <div className="services-item-desc">Need a new website for your company? We are ready to develop it!</div>
                  </span>
                </div>
                <div className="col-lg-4 col-md-6 col-12 item">
                  <span className="services-item services-item-modern item-style">
                    <div className="services-item-ico">
                      <i className="material-icons material-icons-outlined md-48">art_track</i>
                    </div>
                    <div className="services-item-ico-bg">
                      <i className="material-icons material-icons-outlined">art_track</i>
                    </div>
                    <h4 className="services-item-title">Custom Applicaton Developmenmt</h4>
                    <div className="services-item-desc">Having trouble finding software that does what you need?  Reach out to us.  We can create custom software
                to fit your business processes.</div>
                  </span>
                </div>
                <div className="col-lg-4 col-md-12 col-12 item">
                  <span className="services-item services-item-modern item-style">
                    <div className="services-item-ico">
                      <i className="material-icons material-icons-outlined md-48">cloud_download</i>
                    </div>
                    <div className="services-item-ico-bg">
                      <i className="material-icons material-icons-outlined">cloud_download</i>
                    </div>
                    <h4 className="services-item-title">Cloud Development</h4>
                    <div className="services-item-desc">We also provide support and services for most serverless cloud platforms.  Just ask, and most likely
                we can help.</div>
                  </span>
                </div>
                <div className="col-lg-4 col-md-6 col-12 item">
                  <span className="services-item services-item-modern item-style">
                    <div className="services-item-ico">
                      <i className="material-icons material-icons-outlined md-48">contact_phone</i>
                    </div>
                    <div className="services-item-ico-bg">
                      <i className="material-icons material-icons-outlined">contact_phone</i>
                    </div>
                    <h4 className="services-item-title">IT Consulting</h4>
                    <div className="services-item-desc">Need some advising on emerging technologies?  Our team can help advise you on what options
                    are available and what would work best for you and your team.</div>
                  </span>
                </div>
                <div className="col-lg-4 col-md-6 col-12 item">
                  <span className="services-item services-item-modern item-style">
                    <div className="services-item-ico">
                      <i className="material-icons material-icons-outlined md-48">live_help</i>
                    </div>
                    <div className="services-item-ico-bg">
                      <i className="material-icons material-icons-outlined">live_help</i>
                    </div>
                    <h4 className="services-item-title">IT Support</h4>
                    <div className="services-item-desc">Have an application or website and no real support for it?  Contact one of our team and we 
                    will evaluate your situation to see what kind of on-going support we can provide to you.</div>
                  </span>
                </div>
                <div className="col-lg-4 col-md-12 col-12 item">
                  <span className="services-item services-item-modern item-style">
                    <div className="services-item-ico">
                      <i className="material-icons material-icons-outlined md-48">tv</i>
                    </div>
                    <div className="services-item-ico-bg">
                      <i className="material-icons material-icons-outlined">tv</i>
                    </div>
                    <h4 className="services-item-title">UX And Design</h4>
                    <div className="services-item-desc">Our team is equiped with extremely talented graphic designers.  If your website or application needs 
                    an updated look and feel, let us know.  We can provide you with design proposals to take you to the next level of design.</div>
                  </span>
                </div>
              </div>
            </div> 
          </div>
          <Footer />
        </div>
      </main>
    </div>
  );
}
