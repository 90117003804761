import React from "react";
import Footer from '../Footer';
import HeaderMenu from '../HeaderMenu';
import { makeStyles } from "@material-ui/core/styles";


//import styles from "assets/jss/material-kit-react/views/home.js";
import '../../styles/index.css';

const styles = {
  overviewLabel: {
    fontSize: '1.2em'
  }
}

const useStyles = makeStyles(styles);

export default function Home(props) {
  const classes = useStyles();
  //const { ...rest } = props;

  return (
    <div>

      <main className="main">
        <div className="main-inner">
          <HeaderMenu />
          <div class="main-content">
            <div class="container">
              <div class="row content-items">
                <div class="col-12">
                  <div class="wrapp-page-title page-title-center">
                    <div class="page-subtitle">we are always in touch</div>
                    <h1 class="page-title">Contact Us</h1>
                  </div>
                </div>
                {/* <div class="col-md-4 col-12 content-item">
                  <div class="contacts-info-item item-style">
                    <div class="contacts-info-item-ico">
                      <i class="material-icons md-60">location_on</i>
                    </div>
                    <div class="contacts-info-item-content">
                      <a href="https://www.google.com/maps/place/4730+Crystal+Springs+Dr,+Los+Angeles,+CA+90027,+%D0%A1%D0%A8%D0%90/data=!4m2!3m1!1s0x80c2c08ea296b841:0x5030fc6064b8e8d1?sa=X&ved=2ahUKEwijmtvUqZvjAhXjkIsKHYNsDswQ8gEwAHoECAkQAQ">
                        4730 Crystal Springs Dr,  Los Angeles, CA
									</a>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12 content-item">
                  <div class="contacts-info-item item-style">
                    <div class="contacts-info-item-ico">
                      <i class="material-icons md-60 footer-contact-tel">smartphone</i>
                    </div>
                    <div class="contacts-info-item-content">
                      <div>
                        <a href="tel:+13239134688" class="formingHrefTel">+1 323-913-4688</a>
                      </div>
                      <div>
                        <a href="tel:+13238884554" class="formingHrefTel">+1 323-888-4554</a>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="col-md-8 col-12 content-item">
                <div class="col-12">
                  <h3>Reach out to us</h3>
                </div>
                <div class="col-lg-12 content-item">
                  <p className={classes.overviewLabel}>
                    Have an idea of something you would like created?  A website? A custom application for your business.  Give us a call.  With decades of
                    experience available at <span className="primaryColor bold">Envy</span><span className="secondaryColor bold">Soft</span>, we can almost
                    certainly create that custom tool you need for your business.  
                  </p>
                </div>
                </div>
                <div class="col-md-4 col-12 content-item">
                  <div class="contacts-info-item item-style">
                    <div class="contacts-info-item-ico">
                      <i class="material-icons md-60 footer-contact-email">email</i>
                    </div>
                    <div class="contacts-info-item-content">
                      <div>
                        <a href="mailto:info@example.io">info@envysoft.io</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <Footer />
      </main>

    </div>
  );
}
