import React from "react";
import '../styles/index.css';

export default function HeaderMenu(props) {
  return (
    <React.Fragment>
      <nav className="mob-main-mnu">
        <div className="mob-main-mnu-content">
          <ul className="mob-main-mnu-list">
            <li><a href="/">Home</a></li>
            <li><a href="/about-us">About us</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/contact-us">Contact us</a></li>
          </ul>
        </div> 
        <div className="mob-main-mnu-footer">
        </div>
      </nav>
      <header className="header">
        {/* <!-- Begin header top --> */}
        <nav className="header-top">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-auto">
                {/* <!-- Begin header top info --> */}
                <ul className="header-top-info">
                  <li>
                    <a href="mailto:info@envysoft.io">
                      <i className="material-icons">email</i>
                      <span>info@envysoft.io</span>
                    </a>
                  </li>
                </ul>
                {/* <!-- Ennd header top info --> */}
              </div>
              <div className="col-auto">
                <div className="header-top-links">
                  {/* <!-- Begin social links --> */}
                  <ul className="social-links">
                    <li>
                      <a href="https://www.instagram.com/envysoft.io/" title="Instagram">
                        <svg viewBox="0 0 448 512"><use xlinkHref="/img/sprite.svg#instagram-ico"></use></svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/envysoft/" title="LinkedIn">
                        <svg viewBox="0 0 448 512"><use xlinkHref="/img/sprite.svg#linkedin-in-ico"></use></svg>
											</a>
										</li>
                  </ul>
                  {/* <!-- End social links --> */}
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* <!-- End header top -->
				<!-- Begin header fixed --> */}
        <nav className="header-fixed">
          <div className="container">
            <div className="row flex-nowrap align-items-center justify-content-between">
              <div className="col-auto d-block d-lg-none header-fixed-col">
                <div className="main-mnu-btn">
                  <span className="bar bar-1"></span>
                  <span className="bar bar-2"></span>
                  <span className="bar bar-3"></span>
                  <span className="bar bar-4"></span>
                </div>
              </div>
              <div className="col-auto header-fixed-col">
                {/* <!-- Begin logo --> */}
                <a href="/" className="logo logo-ico-widht-text" title="EnvySoft">
                  <img  src="/img/envysoft-logo-2.png" alt="EnvySoft LLC" />
                </a>
              </div>
              <div className="col-lg col-auto col-static header-fixed-col">
                <div className="row flex-nowrap align-items-center justify-content-end">
                  <div className="col-auto header-fixed-col d-none d-lg-block col-static">
                    {/* <!-- Begin main menu --> */}
                    <nav className="main-mnu">
                      <ul className="main-mnu-list">
                        <li>
                          <a href="/" data-title="Home">
                            <span>Home</span>
                          </a>
                        </li>
                        <li>
                          <a href="/about-us" data-title="About us">
                            <span>About us</span>
                          </a>
                        </li>
                        <li className="main-mnu-item-has-child">
                          <a href="/services" data-title="Services">
                            <span>Services</span>
                          </a>
                        </li>
                        <li className="main-mnu-item-has-child">
                          <a href="/contact-us" data-title="Contact Us">
                            <span>Contact Us</span>
                          </a>
                        </li> 
                      </ul>
                    </nav>
                    {/* <!-- End main menu --> */}
                  </div>
                  <div className="col-auto header-fixed-col d-none d-lg-block">
                    
                  </div>
                  <div className="col-auto header-fixed-col col-static">
                    {/* <!-- Begin header search --> */}
                    <div className="header-search">
                      
                    </div>
                    {/* <!-- End header search --> */}
                  </div>
                  <div className="col-auto d-block d-lg-none col-static header-fixed-col">
                    <div className="header-navbar">
                      <div className="header-navbar-btn">
                        <i className="material-icons md-24">more_vert</i>
                      </div>
                      <ul className="header-navbar-content">
                        <li>
                          <a href="mailto:info@envysoft.io">
                            <i className="material-icons">email</i>
                            <span>info@envysoft.io</span>
                          </a>
                        </li>
                        <li>
                          {/* <!-- Begin social links --> */}
                          <ul className="social-links">
                            <li>
                              <a href="https://www.instagram.com/envysoft.io/" title="Instagram">
                                <svg viewBox="0 0 448 512"><use xlinkHref="/img/sprite.svg#instagram-ico"></use></svg>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.linkedin.com/company/envysoft/" title="LinkedIn">
                                <svg viewBox="0 0 448 512"><use xlinkHref="/img/sprite.svg#linkedin-in-ico"></use></svg>
                              </a>
                            </li>
                          </ul>
                          {/* <!-- End social links --> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </nav >
        {/* <!-- End header fixed --> */}
      </header >
    </React.Fragment>

  );
}
