import React from "react";

export default function WhyChooseUs(props) {
  return (
    <section className="section section-bg">
      <div className="container">
        <div className="row items">
          <div className="col-12">
            <div className="wrapp-section-title section-title-center">
              <div className="section-subtitle">some reasons</div>
              <h2 className="section-title">Why Choose Us</h2>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 item">
            <div className="advantages-item">
              <div className="advantages-item-count">01</div>
              <div className="advantages-item-info">
                <h4 className="advantages-item-title">High Quality Software</h4>
                <div className="advantages-item-desc">
                  <p>We develop applications using only the latest methodologies and technologies to ensure a high quality product.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 item">
            <div className="advantages-item">
              <div className="advantages-item-count">02</div>
              <div className="advantages-item-info">
                <h4 className="advantages-item-title">Dedicated 24\7 Support</h4>
                <div className="advantages-item-desc">
                  <p>You can rely on our 24/7 tech support that will gladly solve any app issue you may have.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 item">
            <div className="advantages-item">
              <div className="advantages-item-count">03</div>
              <div className="advantages-item-info">
                <h4 className="advantages-item-title">Day-to-Day Management</h4>
                <div className="advantages-item-desc">
                  <p>Our support team can monitor your sites and applications daily to ensure the highest
                    availability possible.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 item">
            <div className="advantages-item">
              <div className="advantages-item-count">04</div>
              <div className="advantages-item-info">
                <h4 className="advantages-item-title">Agile and Fast Working Style</h4>
                <div className="advantages-item-desc">
                  <p>Our team uses a high speed iterative development methods to ensure a fast delivery.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 item">
            <div className="advantages-item">
              <div className="advantages-item-count">05</div>
              <div className="advantages-item-info">
                <h4 className="advantages-item-title">Expertise in Cloud Applications</h4>
                <div className="advantages-item-desc">
                  <p>To take your application to the next by having our team develop services on most cloud platforms.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 item">
            <div className="advantages-item">
              <div className="advantages-item-count">06</div>
              <div className="advantages-item-info">
                <h4 className="advantages-item-title">Professional Quality Design</h4>
                <div className="advantages-item-desc">
                  <p>Our graphic designers will not stop until the design is nothing short of perfect.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}
